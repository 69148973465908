import React from 'react'
import capitalize from 'lodash/capitalize'
import Head from 'next/head'
import styled from 'styled-components'

import Header from './Header'
import { useUserContext } from 'userContext'
import UpvotyWidget from 'hooks/upvotyWidget'
export interface LayoutProps {
  children: React.ReactElement
  backButton?: React.ReactElement
  hideNavBar?: boolean
  titleText: string
  hasPadding: boolean
}
const HEADER_HEIGHT = 75
const LayoutWrapper = styled.div<LayoutProps>`
  margin: ${HEADER_HEIGHT}px 0 0 0;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  padding: ${props => (props.hasPadding ? '10px 10px 0 10px' : 0)};

  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
const BOARD_HASH = 'd5a6d0ffb8f82db3b6d44d96a2d102071b31c1743e178dfe28a4e70316023484'
const Layout = (props: LayoutProps) => {
  const [showUpvoty, setShowUpvoty] = React.useState<boolean>(false)
  const user = useUserContext()
  return (
    <div>
      <Head>
        <title>Found Clinic - {capitalize(props.titleText)}</title>
      </Head>
      <Header setShowUpvoty={setShowUpvoty} showUpvoty={showUpvoty} backButton={props.backButton} />
      {!showUpvoty && <LayoutWrapper {...props}>{props.children}</LayoutWrapper>}
      {showUpvoty && (
        <UpvotyWidget
          ssoToken={user.user?.upvotyJWT}
          boardHash={BOARD_HASH}
          baseUrl={'found.upvoty.com'}
        />
      )}
      <div id="modal-root"></div>
    </div>
  )
}

export default Layout
